import * as React from 'react';
import Head from 'next/head';
import Link from 'next/link';
import ck from 'camelcase-keys';
import sk from 'snakecase-keys';
import { Button } from 'evergreen-ui';
import { GetServerSideProps } from 'next';
import { StateInspector, useReducer } from 'reinspect';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { setCookie } from 'nookies';
import { useRouter } from 'next/router';

import ChangeLanguage from 'components/authentication/ChangeLanguage';
import InputText from 'components/form/InputText';
import LoadingCover from 'components/layout/LoadingCover';
import removeNonPrintableASCII from 'utils/removeNonPrintableASCII';
import styles from 'src/css/authentication';
import { SSPUser, DSPUser, VendorType, VendorAuth } from 'types/auth';
import { useTranslation } from 'src/i18n';

// 30日間有効
const MAX_TOKEN_AGE = 30 * 24 * 60 * 60;

type State = {
  email: string;
  password: string;
  isFailure: boolean;
  isSubmitting: boolean;
};

enum ActionType {
  SetEmail = 'set-email',
  SetPassword = 'set-password',
  OnFailure = 'on-failure',
  OnSubmit = 'on-submit',
}

type Action =
  | { type: ActionType.SetEmail, payload: string }
  | { type: ActionType.SetPassword, payload: string }
  | { type: ActionType.OnFailure }
  | { type: ActionType.OnSubmit };

const initialState: State = {
  email: '',
  password: '',
  isFailure: false,
  isSubmitting: false,
};

const reducer: React.Reducer<State, Action> = (
  state: State,
  action: Action,
): State => {
  const patch = (diff: Partial<State>): State => ({ ...state, ...diff });
  switch (action.type) {
    case ActionType.SetEmail:
      return patch({ email: action.payload });
    case ActionType.SetPassword:
      return patch({ password: action.payload });
    case ActionType.OnFailure:
      return patch({ isFailure: true, isSubmitting: false });
    case ActionType.OnSubmit:
      return patch({ isSubmitting: true, isFailure: false });
  }
};

export const getServerSideProps: GetServerSideProps = async ({ locale }) => ({  props: { ...await serverSideTranslations(locale || 'ja') } });

const Signin: React.FC = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const [state, dispatch] = useReducer(reducer, initialState);
  React.useEffect(() => {
    const postSignin = async (): Promise<void> => {
      try {
        const res = await fetch('/api/signin', {
          method: 'POST',
          body: JSON.stringify(sk({
            email: state.email,
            password: state.password,
          })),
        });
        if (res.status !== 200) { throw new Error; }
        const user = await res.json().then(json => ck(json)) as SSPUser | DSPUser;
        setCookie({}, 'token', user.token, {
          maxAge: MAX_TOKEN_AGE,
          path: '/',
        });
        switch (user.ownedBy) {
          case 'vendor': {
            const vendorId = user.vendor.id;
            const res_ = await fetch(`/api/vendors/${vendorId}/auth`);
            const auth = await res_.json().then(json => ck(json, { deep: true })) as VendorAuth;
            if (auth.vendorType === VendorType.DSP) {
              router.push(`/vendors/${vendorId}/dsp`);
              return;
            }
            router.push(`/vendors/${vendorId}`);
            return;
          }
          case 'company':
            router.push(`/companies/${user.company.id}`);
            return;
          case 'medium':
            router.push(`/media/${user.medium.id}`);
            return;
          case 'agency':
            router.push(`/agencies/${user.agency.id}`);
            return;
          case 'advertiser':
            router.push(`/advertisers/${user.advertiser.id}`);
            return;
        }

      } catch {
        return dispatch({ type: ActionType.OnFailure });
      }
    };
    if (state.isSubmitting) { postSignin(); }
  }, [
    state.isSubmitting,
    state.email,
    state.password,
    router,
  ]);
  const setEmail = React.useCallback((value: string) => {
    dispatch({ type: ActionType.SetEmail, payload: value });
  }, [dispatch]);
  const setPassword = React.useCallback((value: string) => {
    const payload = removeNonPrintableASCII(value);
    dispatch({ type: ActionType.SetPassword, payload });
  }, [dispatch]);
  const onSubmit = React.useCallback(() => {
    dispatch({ type: ActionType.OnSubmit });
  }, [dispatch]);
  return (
    <>
      <Head>
        <title>{t('signin.title')}</title>
      </Head>
      <StateInspector name="DOOH project">
        {state.isSubmitting && <LoadingCover />}
        <div className="signin-container">
          <div className="signin-inner">
            <div className="signin-title">{t('signin.title')}</div>
            <div className="signin-form">
              <InputText
                id="email"
                inputValue={state.email}
                label={t('users.email')}
                onChangeValue={setEmail}
              />
              <InputText
                id="password"
                inputValue={state.password}
                label={t('password')}
                onChangeValue={setPassword}
                type="password"
              />
              {state.isFailure && (
                <div className="message-failure">
                  <span>{t('signin.failure')}</span>
                </div>
              )}
              <div className="submit-container">
                <Link href="/password-reset-request">
                  <a className="link">{t('signin.navigation.password.reset')}</a>
                </Link>
                <Button
                  appearance="primary"
                  onClick={onSubmit}>
                  {t('signin.submit')}
                </Button>
              </div>
            </div>
          </div>
          <ChangeLanguage />
        </div>
      </StateInspector>
      <style jsx>{styles}</style>
    </>
  );
};

export default Signin;
