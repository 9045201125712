import { Role } from 'types/user';

export enum VendorType {
  DSP = 'DSP',
  SSP = 'SSP',
  SSPDSP = 'SSP,DSP',
}

export enum Owner {
  Vendor = 'vendor',
  Company = 'company',
  Medium = 'medium',
  Agency = 'agency',
  Advertiser = 'advertiser',
}

// common vendor type
export type Vendor = {
  id: number;
  name: string;
  logoUrl: string;
  contactEmailAddress: string;
  contactPhoneNumber: string;
  mcmFlag: boolean;
};

export type VendorAuth = {
  id: number;
  isAdmin: boolean;
  name: string;
  ownedBy: Owner.Vendor;
  role?: Role;
  vendor: Vendor;
  vendorType: VendorType;
  vendorAdditionalFeatures: number[];
};

export type CompanyAuth = {
  company: { id: number, name: string };
  id: number;
  isAdmin: boolean;
  name: string;
  ownedBy: Owner.Vendor;
  role: Role;
  vendor: Vendor;
  vendorAdditionalFeatures: number[];
} | {
  company: { id: number, name: string };
  id: number;
  isAdmin: boolean;
  name: string;
  ownedBy: Owner.Company;
  role: Role;
  vendor: Vendor;
  vendorAdditionalFeatures: number[];
};

export type MediumAuth = {
  company: { id: number, name: string };
  id: number;
  role: Role;
  name: string;
  medium: { id: number, name: string };
  vendor: Vendor;
  ownedBy: Owner.Vendor;
  isAdmin: boolean;
  vendorAdditionalFeatures: number[];
} | {
  company: { id: number, name: string };
  id: number;
  role: Role;
  name: string;
  medium: { id: number, name: string };
  vendor: Vendor;
  ownedBy: Owner.Company;
  isAdmin: boolean;
  vendorAdditionalFeatures: number[];
} | {
  company: { id: number, name: string };
  id: number;
  role: Role;
  name: string;
  medium: { id: number, name: string };
  vendor: Vendor;
  ownedBy: Owner.Medium;
  isAdmin: boolean;
  vendorAdditionalFeatures: number[];
};

export type AgencyAuth = {
  agency: { id: number, name: string, s2SSupplierId?: string };
  id: number;
  role: Role;
  name: string;
  ownedBy: Owner.Vendor;
  vendor: Vendor;
  vendorAdditionalFeatures: number[];
} | {
  agency: { id: number, name: string, s2SSupplierId?: string };
  id: number;
  role: Role;
  name: string;
  ownedBy: Owner.Agency;
  vendor: Vendor;
  vendorAdditionalFeatures: number[];
};

export type AdvertiserAuth = {
  advertiser: { id: number, name: string };
  agency: { id: number, name: string, s2SSupplierId?: string };
  id: number;
  name: string;
  ownedBy: Owner.Vendor;
  role: Role;
  vendor: Vendor;
  vendorAdditionalFeatures: number[];
} | {
  advertiser: { id: number, name: string };
  agency: { id: number, name: string, s2SSupplierId?: string };
  id: number;
  name: string;
  ownedBy: Owner.Agency;
  role: Role;
  vendor: Vendor;
  vendorAdditionalFeatures: number[];
} | {
  advertiser: { id: number, name: string };
  agency: { id: number, name: string, s2SSupplierId?: string };
  id: number;
  name: string;
  ownedBy: Owner.Advertiser;
  role: Role;
  vendor: Vendor;
  vendorAdditionalFeatures: number[];
};

export type SSPUser = {
  id: number;
  name: string;
  ownedBy: 'vendor';
  vendor: { id: number, name: string };
  token: string;
  isAdmin: boolean;
} | {
  id: number;
  name: string;
  ownedBy: 'company';
  company: { id: number, name: string };
  token: string;
  isAdmin: boolean;
} | {
  id: number;
  name: string;
  ownedBy: 'medium';
  medium: { id: number, name: string };
  token: string;
  isAdmin: boolean;
};

export type DSPUser = {
  id: number;
  name: string;
  ownedBy: 'vendor';
  vendor: { id: number, name: string };
  token: string;
  isAdmin: boolean;
} | {
  id: number;
  name: string;
  ownedBy: 'agency';
  agency: { id: number, name: string };
  token: string;
  isAdmin: boolean;
} | {
  id: number;
  name: string;
  ownedBy: 'advertiser';
  advertiser: { id: number, name: string };
  token: string;
  isAdmin: boolean;
};
